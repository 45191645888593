import styled from "styled-components";

export const WorkBody = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: space-around;
  padding-top: 2rem
  min-height: 100%;
  gap: 2em;
  box-sizing: unset !important;
 
  position: relative;
  align-items: center;

  h2{
    display: inline-block;
    alignment: center
    right: 2rem;
    border-bottom: 0.2rem solid var(--blue);
  }
  
  &:before {
    content: "<Projects>";
    font-family: "La Belle Aurore", cursive;
    color: #515152;
    font-size: 1.8rem;
    left: 0.5em;
    position: absolute;

    @media screen and (max-width: 768px) {
      margin-left: 0;
      margin-top: -0.5em;
      left: 0.5em;
      align-items: center;
      justify-content: center;
      align-self: center;
    }
  }

  &:after {
    content: "</Projects>";
    font-family: "La Belle Aurore", cursive;
    color: #515152;
    font-size: 1.8rem;
    position: absolute;
    right: 0.5em;
    bottom: 0px;

    @media screen and (max-width: 768px) {
      left: 0.5em;
      flex-wrap: nowrap;
    }
  }

  @media screen and (max-width: 768px) {
    
    padding: 8rem 0 0 0rem;
    flex-wrap: nowrap;
    gap: 2em;
    align-items: center;
  }
`;

export const WorkContainer = styled.div`
  padding-top: 5em;
  display: flex;
  flex-direction: column
  flex-wrap: wrap;
  align-content: space-between;
  
  @media screen and (max-width: 768px) {
    padding-top: 1em;
    padding-left: 0;
    align-self: center;
    width: auto;
  }
`;
