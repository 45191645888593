import styled from "styled-components";

export const Container = styled.section`
  margin-top: 9rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  

  .hard-skills{
    margin-top: 1.6rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1.8rem;
  }
  .hability{
    display: flex;
    flex-direction: column;
    align-items: center;

    img{
      width: 3.4rem;
    }
  }

  h2{
    display: inline-block;
    margin-bottom: 2rem;
    border-bottom: 0.2rem solid var(--blue);
  }

  h3{
    margin-top: 2rem;
    color: var(--green);
  }

  p{
    font-size: 1.8rem;
    letter-spacing: 0.1rem;
    font-weight: 500;
  }
  
  

  .about-image{
    text-align: center;
   img{
     margin-top: 2rem;
     height: 45rem;
     width: auto;
     border-radius: 12%;
     transition: 0.3s ease-in;
     filter: grayscale(1);
     
     &:hover{
       filter: grayscale(0);
       transform: scale(1.2);
     }
   }
  }

  @media only screen and (max-width: 480px) {
    .about-image {
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-around;
      max-width: 100%;
      margin-top: 4rem;
      margin-bottom: 3rem;
      img{
        height: 27rem;
      }
    }
  }

  @media (max-width: 960px){
    display: block;
    text-align: center;

    .about-image{
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
    }
    img{
      height: 27rem;
    }
    .hard-skills{
      justify-content: center;
    }
    
  }

`;

export const HomeBody = styled.div`
 
  display: flex;
  flex-direction: row;
  min-height: 100%;
  padding-top: 5rem;
  padding-bottom: 5rem;
  position: relative;

  &:before {
    content: "<About>";
    font-family: "La Belle Aurore", cursive;
    color: #515152;
    font-size: 1.8rem;
    margin-left: -1.5em;
    position: absolute;
    @media screen and (max-width: 768px) {
      margin-left: 0.5em;
    }
  }

  &:after {
    content: "</About>";
    font-family: "La Belle Aurore", cursive;
    color: #515152;
    font-size: 1.8rem;
    position: absolute;
    bottom: 0px;
    right: 0.5em;
    padding-top: 10rem;

    @media screen and (max-width: 768px) {
      left: 0.5em;
      
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0;
    padding-top: 9rem;
    padding-bottom: 3rem;
  }
`;
