import styled from "styled-components";


export const Container = styled.footer`
  background-color: #2b2b2b;
  padding: 3rem 15rem;
  margin-top: 10rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo{
    display: flex;
    align-items: center;
    text-align: center;
    
    font-weight: bold;
   img{
     height: 4rem;
     width: auto;
     border-radius: 12%;
   }
  }
  span{
    transform: skew(-8deg);
-webkit-transform: skew(-8deg);
  }

  &:before {
    content: "</html>";
    font-family: "La Belle Aurore", cursive;
    color: #515152;
    font-size: 1.8rem;
    
    position: absolute;
    margin-top: -7.5em;
    right: 2.5em;

    @media screen and (max-width: 768px) {
      margin-left: 0;
      margin-top: -7.5em;
      right: 2.5em;
      align-items: center;
      justify-content: center;
      align-self: center;
    }
  }

  .logo{
    font-size: 2.8rem;
  }

  p{
    letter-spacing: 0.2rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    img{
      width: 2.6rem;
      animation: spinning 5s infinite linear;
    }
  }
  .social-media{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    img,span{
      font-size: 3rem;
      width: 3rem;
    }
  }


  @keyframes spinning {
    0%{
      transform: rotate(0);
    }
    100%{
      transform: rotate(360deg);
    }
  }

  @media(max-width: 800px){
    padding: 4rem 10rem;
    flex-direction: column;
    gap: 2rem;
    text-align: center;
  }
  @media(max-width: 600px){
    padding: 4rem 1rem;
    p{
      font-size: 1.2rem;
    }
  }
`