import { useRef } from "react";
import { useState, useEffect } from "react";
import ScrollAnimation from "react-animate-on-scroll"
import CustomLetter from "../Letter/Letter";
import {
  HomeBody,
  HomeContainer,
  TypeWritter,
  Button,
} from "./HeroElements";

const typewritterStrings: string[] = [
  "Software Developer",
  "Data Analyst",
  "Full Stack Developer",
  "Quant",
];

const Hero = () => {
  const letters: JSX.Element[] = [];
  letters.push(
    <span
      key={Math.random().toString()}
      style={{ fontSize: 60, color: "white" }}
    >
      I'm{" "}
    </span>
  );

  /**
   * Add every letter of the name as separate tag.
   */
  const name: string = "Manan \n";
  for (let i = 0; i < name.length; i += 1) {
    if (name[i] === "\n") {
      letters.push(
        <br
          key={Math.random().toString()}
          style={{ backgroundColor: "transparent" }}
        />
      );
    } else if (name[i] === " ") {
      letters.push(
        <span
          key={Math.random().toString()}
          style={{ backgroundColor: "transparent" }}
        >
          &nbsp;
        </span>
      );
    } else {
      letters.push(
        <CustomLetter key={Math.random().toString()}>{name[i]}</CustomLetter>
      );
    }
  }

  const [text, setText] = useState(typewritterStrings[0]);
  const [direction, setDirection] = useState("forward");
  const index = useRef(0);

  /**
   * Changing typing direction every 'timeInterval' milliseconds and changing the word every two changes of direction.
   */
  const timeInterval: number = 3900;
  useEffect(() => {
    const interval = setInterval(() => {
      if (index.current >= typewritterStrings.length) {
        index.current = 0;
      }

      if (direction === "backward") {
        setText(typewritterStrings[index.current]);
      }

      if (direction === "forward") {
        setDirection("backward");
        index.current += 1;
      } else if (direction === "backward") {
        setDirection("forward");
      }
    }, timeInterval);
    return () => {
      clearInterval(interval);
    };
  }, [direction]);

  return (
    <>
      <HomeBody id="home">
        <HomeContainer>
        <ScrollAnimation animateIn="fadeInUp">
          <span style={{ fontSize: 20, color: "white" }}>Hi There, 👋</span>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" delay={0.2*1000}>
          <div>{letters}</div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" delay={0.4 * 1000}>
          <TypeWritter
            key={Math.random().toString()}
            typing_direction={direction}
          >
            {text}
          </TypeWritter>
          </ScrollAnimation>
          
          <ScrollAnimation animateIn="fadeInUp" delay={0.6 * 1000}>
          <Button to="contact" spy={true} smooth={true} offset={-79}>
            Contact Me
          </Button>
          </ScrollAnimation>
          
        </HomeContainer>
      </HomeBody>
      
    </>
  );
};

export default Hero;