import styled from "styled-components";

export const hardskills = styled.div`

.hard-skills{
  margin-top: 1.6rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.8rem;
}
`
export const SkillsBody = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100%;
  position: relative;
  padding-top: 3rem;
  

  &:before {
    content: "<Skills>";
    font-family: "La Belle Aurore", cursive;
    color: #515152;
    font-size: 1.8rem;
    margin-left: -1.5em;
    position: absolute;

    @media screen and (max-width: 768px) {
      margin-left: 0;
      left: 0.5em;
    }
  }

  &:after {
    content: "</Skills>";
    font-family: "La Belle Aurore", cursive;
    color: #515152;
    font-size: 1.8rem;
    position: absolute;
    bottom: 1em;
    right: 0.5em;

    @media screen and (max-width: 768px) {
      left: 0.5em;
      bottom: -2em;
    }
  }

  

  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0 1em 0 1em;
    padding-top: 2em;
  }
`;

export const SkillsContainer = styled.div<{ justify?: string }>`
  padding-top: 4em;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 50%;
  ${({ justify }) =>
    justify &&
    `
      justify-content: center;
  `};

  h2{
    display: inline-block;
    margin-bottom: 2rem;
    margin-top: 3rem;
    border-bottom: 0.2rem solid var(--blue);
  }
  p{
    font-size: 1.8rem;
    letter-spacing: 0.1rem;
    font-weight: 500;
  }

  @media screen and (max-width: 768px) {
    padding-top: 2em;
    padding-left: 0;
    align-self: center;
    width: auto;
    align-items: center
  }
`;

export const Text = styled.div`
  font-size: 1.4em;
  padding-top: 1.3em;
  line-height: 1.4em;
  color: white;
`;
