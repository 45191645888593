import Project from "../Project/Project";
import { WorkBody, WorkContainer } from "./WorkElements";
import VRP from "./../../assets/VRP&FLP.mp4";
import Turbulance from "./../../assets/Turbulance.mp4";  
import TradingBot from "./../../assets/Trading_Bot.mp4";
import PathFinding from "./../../assets/PathFinding.mp4";
import ScrollAnimation from "react-animate-on-scroll";

const Work = () => {
  const Trading_Bot: string[] = [
    "Python",
    "Pandas",
    "Web-Socket",
    "MySQL",
    "Client-Server",
  ];

  const WarehouseLocator: string[] = [
    "Python",
    "Genetic Algorithm",
    "Savings Algorithm (clarke & wright)",
  ];

  const Turbulence: string[] = [
    "C/C++",
    "OpenFoam",
    "Ansys",
  ];

  const Path_Finding: string[] = [
    "TypeScript",
    "Web Workers",
    "PIXI.JS",
    "WebGL",
  ];

  return (
    <>
      <WorkBody id="projects">
        <WorkContainer style={{ alignSelf: "flex-start" }}>
        <ScrollAnimation animateIn="fadeInLeft" >
         <h2>Some things I've Built</h2> 
         </ScrollAnimation>
        </WorkContainer>
        <div style={{ height: 40 }}></div>
        <ScrollAnimation animateIn="fadeInRight" delay={0.2 * 1000}>
        <Project
          invertedOrientation={false}
          projectName="Trading Bot"
          projectDescription="
           Wrote an algorithm in Python to trade options by using Binomial Black-Scholes option pricing model and
            Monte Carlo simulation. Automated Trades using Interactive Brokers by establishing a network socket to the client application. 
            Data mined live feed from IB platform for live index pricing and volatility by converting raw data into pandas
          data frame"
          video={TradingBot}
          stackItems={Trading_Bot}
        ></Project>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.4 * 1000} >
        <Project
          invertedOrientation={true}
          projectName="Warehouse Locator"
          projectDescription="Wrote an Optimizing algorithm which found a optimal location for placing a warehouse and also found the
          optimal route for delivering packages (more formally: UFLP and VRP). Determined the path by using  savings algorithm (clarke & wright) and used a genetic algorithm for determining location"
          video={VRP}
          stackItems={WarehouseLocator}
        ></Project>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInRight" delay={0.2 * 1000}>
        <Project
          invertedOrientation={false}
          projectName="Turbulence Modeling"
          projectDescription="Developed a hybrid mathematical model to predict the flow and effects of turbulence.
          Used Reynolds averaged navier stokes(RANS) and Large eddy simulation(LES), primarily focuced on larger
          length and larger time scales.
          When there is inadequate spatial and temporal resolution, RANS is utilized; we move to LES when there is
          enough resolution to appropriately resolve small eddies"
          video={Turbulance}
          stackItems={Turbulence}
        ></Project>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.4 * 1000}>
        <Project
          invertedOrientation={true}
          projectName="Path Finding Algorithm"
          projectDescription="Designed and implemented a goal-based vector field pathfinding algorithm.
          Kernel convolution was utilized to form a heatmap which is further was used for making a vector field around
          the goal point. Further optimized the application by multithreading it.
          This in turn could compute path for any point on map with only one calculation, resulting in overall reduced
          processing time and could be used for small to medium maps for moving large amount of particles."
          video={PathFinding}
          stackItems={Path_Finding}
        ></Project>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp" delay={0.6 * 1000}>
        <h1 style={{ color: "white", paddingBottom: "1.2em" }}>
          You can check more of my work in&nbsp;
          <a
            style={{ color: "white", alignSelf: "center" }}
            href="https://github.com/MananPatel-1"
            target="_blank" rel="noreferrer"
          >
            Github
          </a>
        </h1>
        </ScrollAnimation>
      </WorkBody>
    </>
  );
};

export default Work;
