import { Container,HomeBody } from "./styles";
import profileImage from "../../assets/My_photo.jpg"
import ScrollAnimation from "react-animate-on-scroll";



export function About(){
  return(
    <HomeBody>
    <Container id="about">
      <div className="about-text">
        <ScrollAnimation animateIn="fadeInLeft">
          <h2>About me</h2>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.2 * 1000}>
          <p>I am a Software Developer and a student at Brock University majoring in Mathematics and Computer Science located in Canada </p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.4 * 1000} style={{marginTop: "2rem", marginBottom: "2rem"}}>

          <p>I'm currently working on the mutual exclusion problem and my key areas of interest are combinatorial optimization, statistical inference problems, and computational fluid dynamics; I am certain that I can bring all of these concepts to life which are evident in my projects that I worked on over my freshman year.</p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.4 * 1000} style={{marginTop: "2rem", marginBottom: "2rem"}}>

          <p>I enjoy solving technical difficulties, as well as exploring and developing new techniques and software for solving problems. I strive to and challenge myself to always improve by being proactive, hardworking, responsible, and dedicated to finding the best solution to any problem.</p>
        </ScrollAnimation>
      </div>
      <div className="about-image">
        <ScrollAnimation animateIn="fadeInRight" delay={0.6 * 1000}>
          <img src={profileImage}/>
        </ScrollAnimation>
      </div>
    </Container>
    </HomeBody>
  )
}
