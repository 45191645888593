import RotatingSphere from "../RotatingSphere/RotatingSphere";
import { SkillsBody, SkillsContainer } from "./SkillsElements";
import ScrollAnimation from "react-animate-on-scroll";

const Skills = () => {
  return (
    <>
     
      <SkillsBody id="skills">
        <SkillsContainer>
        <ScrollAnimation animateIn="fadeInLeft" >
          <h2>Skills</h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInLeft" delay={0.2 * 1000}>
            <p>I have been coding for 4 years and I have learned to code in C++,
            Python, Java, Typescript, version control with Git, and database
            management with MySQL. I possess strong knowledge in Data
            Structures, Algorithms, and OOP principles.</p>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInLeft" delay={0.4 * 1000} style={{marginTop: "2rem", marginBottom: "2rem"}}>
            <p>I have taken multiple university courses and have base
            knowledge with different programming languages like  C, haskell and PHP.
            I also comfortable with modern web framworks such as React, Laravel and Angular
            </p>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInLeft" delay={0.6 * 1000}>
          <p>I have also taken numerous mathematics and statistics courses at university and through online resources
            by which I have learned quantitative analysis and applications of measure theory towards quantitative finance.
             I also have taken and certified many Coursera courses which
            you can check on my&nbsp;<a
              style={{ color: "white" }}
              href="https://www.linkedin.com/in/manan-patel-492295229"
            >
              LinkedIn
            </a>{" "}
            profile.</p>
        </ScrollAnimation>
        </SkillsContainer>
        <ScrollAnimation animateIn="fadeInRight" delay={0.6 * 1000}>
        <SkillsContainer justify="center">
          <RotatingSphere />
        </SkillsContainer>
        </ScrollAnimation>
      </SkillsBody>
    </>
  );
};

export default Skills;
