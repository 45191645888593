import styled from "styled-components";


export const Container = styled.section`
  margin-top: 3rem;
  margin-bottom: 2rem;
  
  &:before {
    content: "<Contact>";
    font-family: "La Belle Aurore", cursive;
    color: #515152;
    font-size: 1.8rem;
    left: 5.8em;
    position: absolute;

    @media screen and (max-width: 768px) {
      margin-left: 0;
      margin-top: -0.5em;
      left: 2.5em;
      align-items: center;
      justify-content: center;
      align-self: center;
    }
  }

  &:after {
    content: "</Contact>";
    font-family: "La Belle Aurore", cursive;
    color: #515152;
    font-size: 1.8rem;
    position: absolute;
    right: 5.5em;
    bottom: 0px;

    @media screen and (max-width: 768px) {
      
      left: 0.5em;
    }
  }

  header{
    text-align: center;
    h2{
      padding-top: 2rem;
      text-align: center;
      font-size: 4rem;
    }
    p{
      color: var(--green);
      font-weight: 500;
    }
  }
  

  .contacts{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    place-items: center;
    margin-top: 8rem;
    div{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      max-width: 40rem;
      gap: 2rem;
      background-color: var(--green);
      border-radius: 1.4rem;
      padding: 1.6rem 2.8rem;
      transition: background-color 0.25s;
      img{
        width: 4rem;
      }
      a{
        color: var(--black);
        font-weight: 500;
      }
      &:hover{
        background-color: #197c40;
        a{
          color: #FFF;
        }
      }
    }
  }

  


  @media(max-width: 960px){
    margin-top: 6rem;
    .contacts{
      flex-direction: column;
      div{
        width: 100%;
        flex-direction: column;
      }
    }
  }
  
`